import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Talk from "../../components/talk";

import '../../components/index.scss';
import HomeLink from "../../components/home-link";

const SwiftUIPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "talks/swiftui.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = `SwiftUI`;
  const summary = `My second talk as part of the series "Informáticos en Casa", this time introducing SwiftUI. Using
  Combine and Swift UI, both introduced in WWDC19, we coded a simple To Do App.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <Talk
      imageQuery={imageQuery}
      name={title}
      recordingURL={"https://www.youtube.com/watch?v=0aDA5nl_WyQ&feature=youtu.be&t=321"}
      slidesURL={"https://docs.google.com/presentation/d/177u2T7HbVhJEyzCnIOCQL9ock7qP55eoZnSSvaYMsNQ/edit?usp=sharing"}
      year={2020}
      summary={summary}
    />
  </Layout>
};

export default SwiftUIPage
